<template>
  <div class="procureDailySupplier">
    <h1>采购日汇总（发车）</h1>
    <el-form :inline="true">
      <el-form-item label="发货日期">
        <el-date-picker v-model="searchParam.sendDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="searchParam.supplier" filterable style="width: 180px;" clearable>
          <el-option v-for="supplierItem in show.supplierList" :key="supplierItem.id" :label="supplierItem.value" :value="supplierItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="到货状态">
        <el-select v-model="searchParam.hasReceive" filterable style="width: 120px;" clearable>
          <el-option :key="0" label="未到货" :value="false" />
          <el-option :key="1" label="已到货" :value="true" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasStorage" active-text="区分仓储" />
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasSupplier" active-text="区分供应商" />
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasProduct" active-text="区分品名" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-text type="danger">* 按照</el-text>
        <el-text type="danger" v-if="searchParam.hasStorage">仓储、</el-text>
        <el-text type="danger" v-if="searchParam.hasSupplier">供应商、</el-text>
        <el-text type="danger" v-if="searchParam.hasProduct">品名、</el-text>
        <el-text type="danger">单价、运费不同统计车数和数量</el-text>
      </el-form-item>
      <el-form-item>
        <el-button-group>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);" show-summary :summary-method="summary">
      <el-table-column prop="storage" label="仓储" show-overflow-tooltip v-if="show.tableStorage" />
      <el-table-column prop="supplier" label="供应商" show-overflow-tooltip v-if="show.tableSupplier" />
      <el-table-column prop="product" label="煤矿品名" show-overflow-tooltip v-if="show.tableProduct" />
      <el-table-column prop="truckQuantity" label="车数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.truckQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="sendQuantity" label="发货数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="sendProductPrice" label="煤矿单价" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendProductPrice) }}
        </template>
      </el-table-column>
      <el-table-column prop="sendTransportPrice" label="运费单价" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendTransportPrice) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import util from '../../utils/util'
import math from '../../utils/math'

const tabRef = ref()

const data = reactive({
  list: [],
  summary: {},
})

const show = reactive({
  tableStorage: true,
  tableSupplier: true,
  selected: [],
  supplierList: [],
  storageList: [],
})
api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  show.supplierList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const now = new Date()
const yesterday = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000)
const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.sendDates = [yesterday, yesterday]
  searchParam.hasStorage = false
  searchParam.hasSupplier = false
  searchParam.hasProduct = false
  searchParam.hasReceive = null
  searchParam.supplier = null
  searchParam.storage = null
}
resetSearchParam()

const getPageParam = () => {
  const params = JSON.parse(JSON.stringify(searchParam))
  if (searchParam.sendDates && searchParam.sendDates.length == 2) {
    params.sendDateGe = util.parseTime(searchParam.sendDates[0], '{y}-{m}-{d}')
    params.sendDateLe = util.parseTime(searchParam.sendDates[1], '{y}-{m}-{d}')
  }
  params.hasReceive = searchParam.hasReceive
  params.supplier = searchParam.supplier
  params.storage = searchParam.storage
  show.tableStorage = params.hasStorage
  show.tableSupplier = params.hasSupplier
  show.tableProduct = params.hasProduct
  return params
}

const commitFindList = () => {
  api.post('/backend/procure/getDaily', getPageParam()).then(res => {
    data.list = res.list
    data.summary = res.summary
  })
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const summary = () => {
  const result = []
  if (show.tableStorage) {
    result.push('')
  }
  if (show.tableSupplier) {
    result.push('')
  }
  if (show.tableProduct) {
    result.push('')
  }
  result.push(
    math.formatNumber(data.summary.truckQuantity),
    math.formatNumber(data.summary.sendQuantity),
    math.formatNumber(data.summary.sendProductPrice),
    math.formatNumber(data.summary.sendTransportPrice)
  )
  return result
}
</script>

<style lang="less"></style>